<template>
    <div class="corporateInformation-view">
      <el-tabs v-model="activeName">
        <el-tab-pane label="运费管理" name="first">
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="120px"
            class="ruleForm"
          >
            <el-form-item label="包邮金额(元)" prop="amount">
                 <el-input-number v-model="form.amount" :precision="2" :min="0.00" :max="2147483647" size="medium" placeholder="包邮金额"/>
            </el-form-item>
            <el-form-item label="默认运费(元)" prop="freightAmount">
                <el-input-number  v-model="form.freightAmount" :precision="2" :min="0.00" :max="2147483647" size="medium" placeholder="默认运费"/>
            </el-form-item>
            <el-form-item>
              <el-button
                class="save"
                type="primary"
                @click="submitForm('form')"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
  import {get,put} from "@/utils/request";
  export default {
    name: "freightNew",
    data() {
      let token = localStorage.getItem("token")
      return {
        form: { 
          freightAmount:0.00,
          amount:0.00
        },
        activeName:'first',
        rules: {
        amount: [
          { required: true, message: '请填写包邮金额', trigger: 'blur' }
        ],
        freightAmount: [
          { required: true, message: '请填写默认运费', trigger: 'blur' }
        ],
      },
      };
    },
    mounted(){
      this.getFreight()
    },
    created() {
      if(JSON.parse(localStorage.getItem('cId'))) {
        this.hasPermi = false
      }
    },
    computed: {
      uploadDisabled(){
        return this.fileList.length !== 0;
      },
    },
    beforeDestroy() {
      // ac.removeEventListener("onConfirm", this.setValue);
    },
    methods: {
  
      /**@method 提交表单 */
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            put('/freight',this.form)
            .then(res=>{
                this.getFreight()
                this.$message({
                  type:'success',
                  message: "保存成功",
                })
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      //获取公司信息
      getFreight(){
        get('/freight/getFreight',{cId:JSON.parse(localStorage.getItem('info')).companyId}).then(res=>{
            console.log('数据',res)
            if(res){
              this.form = res
            }else{
              this.form.amount=0.00
              this.form.freightAmount = 0.00
            }
            
        })
      },
      
    },
  };
  </script>
  
  <style lang="scss" scoped>
  /deep/ .disabled .el-upload--picture-card {
      display: none;
  }
  .corporateInformation-view {
    padding: 16px 24px;
  }
  /deep/.el-tabs__content{
    background: #ffffff;
  }
  .ruleForm {
    margin-top: 24px;
  }
  .cancel {
    border: 1px solid rgba(81, 203, 205, 0.4);
    color: #51cbcd;
  }
  .save {
    background: #51cbcd;
    border: 1px solid #51cbcd;
  }
  
  .typeWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 20px;
  }
  .typeBlock{
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    width: 46%;
    margin: 0 10px 10px 10px;
    display: inline-block;
  }
  .typeTitle{
    height: 30px;
    border-bottom: 1px solid #ccc;
    line-height: 30px;
    padding-left: 10px;
    background: #efefef;
    color: #333;
  }
  .typeSetBox{
    padding: 20px 10px 0 0;
  }
  .tip{
    color: #f00;
    font-size: 12px;
    padding-left: 28px;
    position: relative;
    top: 10px;
  }
  </style>
  